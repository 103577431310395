import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControlLabel, Switch } from '@material-ui/core';

import { ReactComponent as ListIt } from '../../SVG/listIt.svg';
import { ReactComponent as RandInactive } from '../../SVG/random_inactive.svg';
import { ReactComponent as RandActive } from '../../SVG/random_active.svg';

import Players from '../Players/Players';
import './Home.less';

const Home = ({ startGame, playerList, setPlayerList, setIsNSFW, isNSFW, isRandom, setIsRandom }) => {
  const { t } = useTranslation();

  const toggleRandom = () => {
    setIsRandom(!isRandom);
  };

  return (
    <div className='fullScreen home'>
      <div className='title'>
        <ListIt className='iconTitle' />
        <h1>List it</h1>
      </div>
      <Players playerList={playerList} setPlayerList={setPlayerList} />
      <div className='switchAndButton'>
        <FormControlLabel
          control={<Switch checked={isNSFW} onChange={() => setIsNSFW(!isNSFW)} color='primary' />}
          label={<div className='labelSwitch'>NSFW</div>}
          className='switchNSFW'
        />
        {isRandom ? (
          <RandActive onClick={toggleRandom} className='randomSVG' />
        ) : (
          <RandInactive onClick={toggleRandom} className='randomSVG' />
        )}
        <Button
          variant='contained'
          color='primary'
          onClick={() => startGame()}
          disabled={playerList.length < 2}
          className='playButton'
        >
          {t('common.start')}
        </Button>
      </div>
    </div>
  );
};

export default Home;
