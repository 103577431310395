import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithClear from '../InputWithClear/InputWithClear';
import './Players.less';

const Players = ({ playerList, setPlayerList }) => {
  const { t } = useTranslation();

  const endOfPlayerList = useRef(null);

  useEffect(() => {
    const names = playerList.map((p) => ({
      name: p.name,
    }));
    localStorage.setItem('players', JSON.stringify(names));
  }, [playerList]);

  const clearPlayer = (index) => {
    const newPL = [...playerList];
    newPL.splice(index, 1);
    setPlayerList(newPL);
  };

  const setPlayerName = (name, i) => {
    const was = playerList.length;
    const newPL = [...playerList];
    const upperise = name[0]?.toUpperCase() + name.substr(1);
    newPL[i] = { name: name.length > 0 ? upperise : '', score: 0 };
    setPlayerList(newPL);
    if (was < newPL.length) {
      setTimeout(() => {
        endOfPlayerList.current?.scrollIntoView({ behavior: 'smooth' }); //, block: 'end', inline: 'nearest' });
      }, 50);
    }
  };

  return (
    <div className='playerList custom-scrollbar'>
      {[...playerList, {}].map((p, i) => (
        <InputWithClear
          key={i}
          className='player'
          label={`${t('players.player')} n°${i + 1}`}
          value={p.name || ''}
          setValue={(newName) => setPlayerName(newName, i)}
          clearValue={() => clearPlayer(i)}
          placeholder={''}
        />
      ))}
      <div ref={endOfPlayerList} />
    </div>
  );
};

export default Players;
