/**
 * Fonction random retourne entre : [min,max[
 * --> JAMAIS = MAX ! et valeur pas dans notIn
 * --> Si impossible retourne NULL
 * @param {number} min
 * @param {number} max
 * @param {Array} notIn
 */
export const rand = (min, max, notIn = []) => {
  // Populate an Array with all possibles values
  let range = [];
  for (let i = min; i < max; i++) {
    // On dégage les valeurs notIn
    if (notIn.indexOf(i) === -1) {
      range.push(i);
    }
  }

  if (range.length > 0) {
    const rangeMax = range.length;
    let r = Math.floor(Math.random() * rangeMax);
    return range[r];
  } else {
    return null;
  }
};

/**
 * Shuffles array ES6 version
 * @param {Array} a
 */
export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};
