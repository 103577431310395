import React from 'react';
import { Button } from '@material-ui/core';

import { ReactComponent as Crown } from '../../SVG/crown.svg';
import './End.less';

const End = ({ swipeIndex, setSwipeIndex, playerList, setPlayerList, restartGame }) => {
  const cleanPL = () => {
    setPlayerList((pl) => pl.map((p) => ({ ...p, score: 0 })));
  };

  const goHome = () => {
    cleanPL();
    setSwipeIndex(0);
  };

  const playAgain = () => {
    cleanPL();
    restartGame();
  };

  if (swipeIndex !== 2) {
    return null;
  }
  const sorted = playerList.sort((a, b) => b.score - a.score);
  const bestScore = sorted[0].score;

  return (
    <div className='fullScreen end'>
      <div className='scores custom-scrollbar'>
        {sorted.map((p, index) => (
          <div key={index} className='player'>
            <div className='score'>{p.score || 0}</div>
            <div> {p.name.length > 13 ? `${p.name.substr(0, 10)}...` : p.name}</div>
            {p.score === bestScore && <Crown className='crown' />}
          </div>
        ))}
      </div>
      <div className='actions'>
        <Button variant='contained' color='secondary' onClick={goHome} className='btnAction'>
          Changer de joueurs
        </Button>
        <Button variant='contained' color='primary' onClick={playAgain} className='btnAction'>
          Rejouer
        </Button>
      </div>
    </div>
  );
};

export default End;
