import { Button, IconButton, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import TicTac from '../../Sounds/tictac.mp3';
import Buzzer from '../../Sounds/buzzer.mp3';
import { ReactComponent as Ok } from '../../SVG/ok.svg';
import { ReactComponent as Ko } from '../../SVG/ko.svg';
import './Game.less';

let t = null;
const TIMER_INTERVAL = 100; // Interval de MaJ du compteur et de la progress bar
const MAX_TIME = 9 * 1000; // Temps à dispo pour répondre à la question (en ms)

const tictac = new Audio(TicTac);
const buzzer = new Audio(Buzzer);
tictac.loop = true;

const Game = ({ game, playerList, setPlayerList, setSwipeIndex }) => {
  const [index, setIndex] = useState(-1);
  const [time, setTime] = useState(0);

  const next = () => {
    if (index + 1 >= game.length) {
      setSwipeIndex(2);
      setIndex(-1);
    } else {
      setIndex((i) => i + 1);
      tictac.currentTime = 0;
      tictac.play();
      setTime(0);
      t = setTimeout(tick, TIMER_INTERVAL);
    }
  };

  const tick = () => {
    setTime((time) => time + TIMER_INTERVAL);
    clearTimeout(t);
    t = setTimeout(tick, TIMER_INTERVAL);
  };

  //Detect end of timer
  useEffect(() => {
    if (time >= MAX_TIME + TIMER_INTERVAL) {
      tictac.pause();
      buzzer.play();
      clearTimeout(t);
      t = null;
    }
  }, [time]);

  // Clear timer on unmount
  useEffect(() => {
    return () => {
      clearTimeout(t);
      t = null;
    };
  }, []);

  const givePoint = (cb) => {
    const newPL = [...playerList];
    const newScore = (newPL[game[index].playerIndex].score || 0) + cb;
    newPL[game[index].playerIndex].score = newScore;
    setPlayerList(newPL);
    next();
  };

  if (index >= game.length) {
    return null;
  }
  if (game.length === 0 || index === -1) {
    return (
      <div className='fullScreen game'>
        <Button color='primary' variant='contained' onClick={next} className='goButton'>
          Go
        </Button>
      </div>
    );
  }
  let sec = Math.ceil((MAX_TIME - time) / 1000);
  if (sec < 0) {
    sec = 0;
  }
  const progress = (time / MAX_TIME) * 100;
  return (
    <div className='fullScreen game'>
      <div className='wrapper'>
        <div className='text'>{game[index].rule}</div>
        <div className='progress'>
          <LinearProgress variant='determinate' value={progress > 100 ? 100 : progress} />
          <div className='second'>{sec}</div>
        </div>
      </div>
      {time >= MAX_TIME + TIMER_INTERVAL && (
        <div className='choices'>
          <IconButton onClick={() => givePoint(0)}>
            <Ko className='choice' />
          </IconButton>
          <IconButton onClick={() => givePoint(1)}>
            <Ok className='choice' />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default Game;
