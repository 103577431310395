import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { rand, shuffle } from './utils';
import Home from './Components/Home/Home';
import Game from './Components/Game/Game';
import End from './Components/End/End';
import { version } from '../package.json';
import './App.less';

// Définit le temps ennviron d'une partie (30 questions à répartir entre les joueurs arrondis au supérieur)
const MAX_TOTAL_QUESTIONS = 30;

let QUESTIONS = [];
let NSFW = [];

const App = () => {
  const [swipeIndex, setSwipeIndex] = useState(0);
  const initPlayer = localStorage.getItem('players');
  const [playerList, setPlayerList] = useState(initPlayer ? JSON.parse(initPlayer) : []);

  const [game, setGame] = useState([]);
  const [notIn, setNotIn] = useState([]);
  const [isWarning, setIsWarning] = useState(false);
  const [isNSFW, setIsNSFW] = useState(true);
  const [isRandom, setIsRandom] = useState(false);

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    // current db in localstorage :
    const db = JSON.parse(localStorage.getItem('db'));
    let useLocalDB = false;
    fetch('./data.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((bdd) => {
        if (!db || db?.questions?.length < bdd?.questions?.length) {
          QUESTIONS = bdd.questions;
          NSFW = bdd.nsfw;
          localStorage.setItem('db', JSON.stringify(bdd));
          console.log(
            `>>> LOCAL DB UPDATED : ${db?.questions?.length + db?.nsfw?.length} --> ${
              bdd?.questions.length + bdd?.nsfw.length
            } <<<`
          );
        } else {
          useLocalDB = true;
        }
      })
      .catch((err) => {
        console.error('Erreur de fetch sur la bdd =', err);
        useLocalDB = true;
      })
      .finally(() => {
        // If something went wrong fetching db (error or wrong number of rules )
        // --> use the one from localStorage !
        if (useLocalDB && db?.questions?.length > 0) {
          QUESTIONS = db.questions;
          NSFW = db.nsfw || [];
          console.log(`~~ using local db nb=${QUESTIONS.length + NSFW.length} ~~`);
        }
      });
  };

  const startGame = (pNotIn = []) => {
    const turnsByPlayers = Math.ceil(MAX_TOTAL_QUESTIONS / playerList.length);
    let myNotIn = [...pNotIn];
    const myGame = [];

    // Gère le NSFW
    const ALL = isNSFW ? QUESTIONS.concat(NSFW) : QUESTIONS;

    if (ALL.length < 1) {
      alert("Erreur : Aucune règle n'a pu être chargée");
      return;
    }

    // Checks si il y a assez de règles avec prise en compte du notIn :
    const nbQuestionsDispo = ALL.length - myNotIn.length;
    if (nbQuestionsDispo < turnsByPlayers * playerList.length) {
      setIsWarning(true);
      myNotIn = [];
    }

    for (let i = 0; i < turnsByPlayers; i++) {
      playerList.forEach((p, playerIndex) => {
        const r = rand(0, ALL.length, myNotIn);
        if (r !== null) {
          myNotIn.push(r);
          const rule = ALL[r].replace('{p}', p.name);
          myGame.push({ rule, playerIndex });
        }
      });
    }

    // Mélange -ou non- l'ordre des joueurs
    if (isRandom) {
      shuffle(myGame);
    }

    setGame(myGame);
    setNotIn(myNotIn);
    setSwipeIndex(1);
  };

  const restartGame = () => {
    startGame(notIn);
  };

  return (
    <>
      <SwipeableViews resistance index={swipeIndex} disabled className='swipViews'>
        <Home
          startGame={startGame}
          playerList={playerList}
          setPlayerList={setPlayerList}
          setIsNSFW={setIsNSFW}
          isNSFW={isNSFW}
          isRandom={isRandom}
          setIsRandom={setIsRandom}
        />
        <Game setSwipeIndex={setSwipeIndex} game={game} playerList={playerList} setPlayerList={setPlayerList} />
        <End
          swipeIndex={swipeIndex}
          setSwipeIndex={setSwipeIndex}
          playerList={playerList}
          setPlayerList={setPlayerList}
          restartGame={restartGame}
        />
      </SwipeableViews>
      <div className='version'>v{version}</div>
      <Dialog
        open={isWarning}
        onClose={() => setIsWarning(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>Pas assez de règles !</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Malheureusement vous avez épuisé les règles actuellement présentes dans le jeu, vous risquez maintenant de
            retomber sur certaines déjà jouées.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsWarning(false)} color='primary' autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default App;
