import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  type: 'dark',
  primary: {
    main: '#32bea6',
  },
  secondary: {
    main: '#651fff',
  },
};
const themeName = 'MyTheme';
export default createMuiTheme({ palette, themeName });
