import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const InputWithClear = ({ id, label, value, setValue, clearValue, placeholder, className, onFocus = () => {} }) => {
  const clear = clearValue
    ? clearValue
    : () => {
        setValue('');
      };

  const myId = id ? 'inputText-' + id : 'inputText-' + label;
  return (
    <FormControl className={className}>
      <InputLabel htmlFor={myId}>{label}</InputLabel>
      <Input
        id={myId}
        inputProps={{ 'data-testid': myId }}
        placeholder={placeholder || ''}
        value={value}
        onFocus={onFocus}
        autoComplete='off'
        onChange={(e) => setValue(e.target.value)}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={clear} data-testid={`${id}-clear-button`}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

InputWithClear.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputWithClear;
